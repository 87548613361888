import logger from 'signale'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { EventType } from '../../../server/db/EventType'
import { NextSeo, EventJsonLd } from 'next-seo'
import NextLink from 'next/link'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useUser } from '../../hooks/use-user'

import { FaCheck } from 'react-icons/fa'

import {
  Box,
  Flex,
  Button,
  Text,
  useDisclosure,
  useClipboard,
  useToast
} from '@chakra-ui/core'

import Head from 'next/head'
import useSWR from 'swr'
// import '@uppy/core/dist/style.css'
// import '@uppy/dashboard/dist/style.css'
// import 'emoji-mart/css/emoji-mart.css'
import dynamic from 'next/dynamic'
import publicEventTypeService from '../../../server/services/publicEventType'
import { BookInfo } from '../../components/book/book-info'
import { BookCalendar } from '../../components/book/book-calendar'
import { BookStageButton } from '../../components/book/book-stage-button'
import { BookMethods } from '../../components/book/book-methods'
import { BaseHeader } from '../../components/base-header'

dayjs.extend(timezone)
dayjs.extend(utc)

const ShareModal = dynamic(() => import('../../components/share-modal'))
const EmbedModal = dynamic(() => import('../../components/embed-modal'))
const BookModal = dynamic(() => import('../../components/book/book-modal'))

export const StageOne = ({
  eventType,
  author,
  date,
  onDateClick,
  onMonthChange,
  includeTimes,
  includeDates,
  backPanelColor,
  textColor,
  dayColor,
  borderColor,
  oldDaysColor,
  weekDaysColor,
  buttonColor
}) => {
  return (
    <Flex
      height={['auto', 'auto', '445px', '445px']}
      flexDirection={['column', 'row', 'row', 'row']}
      justifyContent='center'
      mt={['20px', '7.5vh', '7.5vh', '7.5vh']}
      alignItems='center'
    >
      <BookInfo
        backPanelColor={backPanelColor}
        title={eventType.title}
        background={eventType.background.url}
        description={eventType.description}
        duration={eventType.duration}
        emoji={eventType.emoji}
        author={author.displayName}
        textColor={textColor}
        borderColor={borderColor}
        attendees={[eventType.author.displayName]}
      />
      <BookCalendar
        backPanelColor={backPanelColor}
        date={date}
        onDateClick={onDateClick}
        onMonthChange={onMonthChange}
        duration={eventType.duration}
        includeTimes={includeTimes}
        includeDates={includeDates}
        textColor={textColor}
        dayColor={dayColor}
        borderColor={borderColor}
        oldDaysColor={oldDaysColor}
        weekDaysColor={weekDaysColor}
        buttonColor={buttonColor}
      />
    </Flex>
  )
}

const StageTwo = ({
  eventType, author, integrations,
  setMethod, method, dayColor,
  backPanelColor, textColor, borderColor, buttonColor
}) => {
  return (
    <Flex
      height={['100%', '445px', '445px', '445px']}
      justifyContent='center'
      flexDirection={['column', 'row', 'row', 'row']}
      mt={['20px', '7.5vh', '7.5vh', '7.5vh']}
      alignItems='center'
    >
      <BookInfo
        title={eventType.title}
        background={eventType.background.url}
        description={eventType.description}
        duration={eventType.duration}
        emoji={eventType.emoji}
        author={author.displayName}
        attendees={[eventType.author.displayName]}
        backPanelColor={backPanelColor}
        textColor={textColor}
        borderColor={borderColor}
        buttonColor={buttonColor}
      />
      <BookMethods
        integrations={integrations}
        setMethod={setMethod}
        method={method}
        backPanelColor={backPanelColor}
        textColor={textColor}
        borderColor={borderColor}
        dayColor={dayColor}
        buttonColor={buttonColor}
      />
    </Flex>
  )
}

const StageThree = ({ eventCode, author, headerColor, buttonColor, backPanelColor }) => {
  return (
    <Box>
      {eventCode && (
        <Flex justifyContent='center' marginBottom='23px'>
          <Flex
            width='68px'
            height='68px'
            alignItems='center'
            justifyContent='center'
            background='#4065FF'
            borderRadius='100%'
          >
            <Box as={FaCheck} size='26px' color='#fff' />
          </Flex>
        </Flex>
      )}

      <Text
        fontWeight='700'
        textAlign='center'
        color={'#' + (headerColor || '000000')}
        fontSize='2.5rem'
        lineHeight='2.8125rem'
        marginBottom='20px'
      >
        {eventCode ? 'The meeting is booked' : 'Check your telegram for the event link'}
      </Text>
      {eventCode && (
        <Flex justifyContent='center' marginBottom='45px'>
          <Text width='526px' textAlign='center' color={'#' + (headerColor || '000000')}>
            We are waiting for confirmation from {author}. You will receive a
            notification about the status of your booking
          </Text>
        </Flex>
      )}
      {eventCode && (
        <Flex justifyContent='center'>
          <NextLink href={`/${eventCode}`} target='_blank' passHref>
            <Button
              as='a'
              padding='10px 20px'
              minWidth='303px'
              fontSize='1.125rem'
              background={'#' + (buttonColor || '4065FF')}
              fontWeight='600'
              lineHeight='1.75rem'
              height='48px'
              color={
                `#${backPanelColor || 'ffffff'}`
              }
              borderRadius='6px'
              _hover={{ background: '#0031ff' }}
            >
              Go to event page
            </Button>
          </NextLink>
        </Flex>
      )}
    </Box>
  )
}

function BookPage ({
  eventType: initialEventType,
  slots: initialSlots,
  author: initialAuthor,
  year: initialYear,
  month: initialMonth,
  iframe, bgColor, textColor, borderColor,
  backPanelColor, dayColor, headerColor,
  oldDaysColor, weekDaysColor, buttonColor
}) {
  const { user } = useUser()

  const [stage, setStage] = useState(0)
  const [year, setYear] = useState(initialYear)
  const [month, setMonth] = useState(initialMonth)
  const [booking, setBooking] = useState(false)
  const [eventCode, setEventCode] = useState(null)
  const [date, setDate] = useState(new Date())
  const [method, setMethod] = useState()

  const {
    data: { eventType, slots, author },
    mutate: updateEventType
  } = useSWR(
    ['/api/book', initialEventType.slug, year, month],
    (url, slug, year, month) =>
      axios
        .get(`${url}/${slug}?year=${year}&month=${month}`)
        .then((res) => res.data),
    {
      initialData: {
        eventType: initialEventType,
        slots: initialSlots,
        author: initialAuthor
      }
    }
  )

  const { data: integrations } = useSWR(
    stage === 1 ? ['/api/integrations/available', date] : null,
    (url, _date) =>
      axios.post(url, {
        date: _date.toISOString(),
        eventType: initialEventType.id
      }).then(response => response.data)
  )

  useEffect(() => {
    updateEventType()
  }, [year, month, updateEventType])

  const postParrentHeight = () => {
    const body = document.body
    const html = document.documentElement

    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight)

    window.parent.postMessage({ type: 'hapen', height }, '*')
  }

  useEffect(() => {
    setTimeout(() => postParrentHeight(), 1000)
  }, [])

  useEffect(() => {
    postParrentHeight()
  }, [date])

  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare
  } = useDisclosure()

  const {
    isOpen: isOpenEmbed,
    onOpen: onOpenEmbed,
    onClose: onCloseEmbed
  } = useDisclosure()

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail
  } = useDisclosure()

  const url = `https://hapen.io/${initialEventType.url}`

  const { onCopy } = useClipboard(url)
  const toast = useToast()

  const copyToClipboard = ({ target }) => {
    target.setSelectionRange(0, target.value.length)

    onCopy(url)
    toast({
      title: 'URL copied to clipboard!',
      description: 'Now share it with people you want to share with :)',
      status: 'success',
      duration: 1700
    })
  }

  const defaultMetaDescription = `Book the ${initialEventType.title} event by ${initialAuthor.name} ⏰`

  const meta = {
    title: initialEventType.title,
    description: defaultMetaDescription
  }

  async function onShare () {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Hapen',
          url: window.location.href
        })
      } catch (e) {}
    } else {
      onOpenShare()
    }
  }

  const onMonthChange = (date) => {
    setMonth(date.getMonth() + 1)
    setYear(date.getFullYear())
  }

  const onDateClick = (date, e) => {
    setDate(date)

    if (!e) {
      setBooking(true)
    }
  }

  const normalizedSlots = useMemo(
    () =>
      slots.reduce((acc, slot) => {
        const formatted = dayjs(slot).format('DD-MM-YYYY')
        const timezoned = dayjs(slot).toDate()

        return {
          ...acc,
          [formatted]: [...(acc[formatted] ? acc[formatted] : []), timezoned]
        }
      }, {}),
    [slots]
  )

  // const includeDates = slots.map((slot) => new Date(slot))
  const includeDates = Object.keys(normalizedSlots).map((slot) => normalizedSlots[slot][Math.round((normalizedSlots[slot].length - 1) / 2)])

  const includeTimes = normalizedSlots[dayjs(date).format('DD-MM-YYYY')] || []

  const stages = [
    <StageOne
      key='stage-1'
      eventType={eventType}
      author={author}
      date={date}
      onDateClick={onDateClick}
      onMonthChange={onMonthChange}
      includeTimes={includeTimes}
      includeDates={includeDates}
      backPanelColor={backPanelColor}
      textColor={textColor}
      dayColor={dayColor}
      borderColor={borderColor}
      oldDaysColor={oldDaysColor}
      weekDaysColor={weekDaysColor}
      buttonColor={buttonColor}
    />,
    <StageTwo
      key='stage-2'
      eventType={eventType}
      author={author}
      integrations={integrations}
      method={method}
      setMethod={setMethod}
      backPanelColor={backPanelColor}
      textColor={textColor}
      borderColor={borderColor}
      buttonColor={buttonColor}
      dayColor={dayColor}
    />,
    <StageThree
      key='stage-3'
      eventCode={eventCode}
      author={author.displayName}
      backPanelColor={backPanelColor}
      textColor={textColor}
      borderColor={borderColor}
      buttonColor={buttonColor}
      headerColor={headerColor}
    />
  ]

  const headings = [
    'Select the time of the call',
    'Where to send information about the event'
  ]

  const actions = ['Choose date', 'Book', 'Go to event page']

  const processNext = useCallback(() => {
    if (stage === 0) {
      setStage(1)
    } else {
      if (method === 'email') {
        onOpenEmail()
      } else if (method === 'telegram') {
        window.open(integrations.telegram.url)
        setStage(2)
      }
    }
  }, [stage, method])

  const onSuccess = ({ code }) => {
    onCloseEmail()
    setEventCode(code)
    setStage(2)
  }

  return (
    <>
      <Head>
        {/* <link rel='shortcut icon' href={emojiImageURL} type='image/x-icon' /> */}
        {/* <link
          rel='preload'
          href={Emoji.defaultProps.backgroundImageFn('apple', '64')}
          as='image'
        /> */}
      </Head>
      <NextSeo
        title={meta.title}
        description={meta.description}
        twitter={{
          cardType: 'summary_large_image',
          site: '@HapenApp',
          handle: '@HapenApp'
        }}
        openGraph={{
          title: meta.title,
          description: meta.description
          // images: [
          //   {
          //     alt: 'Event Background',
          //     url: `https://hapen.io/api/common/og-image/${initialEventType.url}`
          //   }
          // ]
        }}
        siteName='Hapen'
      />
      {/* <EventJsonLd
        name={meta.title}
        startDate={initialEventType.date}
        url={`https://hapen.io/${initialEvent.url}`}
        description={meta.description}
        endDate={initialEventType.date}
        location={{
          address: '',
          name: '',
          sameAs: ''
        }}
      /> */}
      <Box
        background={bgColor && bgColor === 'transparent' ? bgColor : '#' + (bgColor || 'F1F2F3')}
        minHeight={['100vh', '100vh', '100vh', '100vh']}
        p='20px'
        display='flex'
        flexDirection='column'
      >
        {isOpenShare && (
          <ShareModal
            onClose={onCloseShare}
            onCopyToClipboard={copyToClipboard}
            url={window.location.href}
          />
        )}
        {isOpenEmail && (
          <BookModal
            onClose={onCloseEmail}
            date={date}
            onSuccess={onSuccess}
            id={eventType.id}
          />
        )}
        {isOpenEmbed && (
          <EmbedModal
            onClose={onCloseEmbed}
            onCopyToClipboard={copyToClipboard}
            url={window.location.href}
            eventTypeCode={eventType.slug}
          />
        )}
        <Flex flexDirection='column' justifyContent='space-between' minHeight='90vh'>
          <Box>
            {!iframe && <BaseHeader user={user} onShare={onShare} onOpenEmbed={onOpenEmbed} />}
            <Flex flexDirection={['column-reverse', 'column', 'column', 'column']}>
              {
                !iframe && <Flex
                  justifyContent='center' marginTop={['20px', '0', '0', '0']}
                  marginBottom={['0', '7.5vh', '7.5vh', '7.5vh']}
                           >
                  <BookStageButton
                    isActive={stage === 0}
                    index={1}
                    onClick={() => setStage(0)}
                  />
                  <BookStageButton
                    isActive={stage === 1}
                    index={2}
                  />
                  <BookStageButton
                    isActive={stage === 2}
                    index={3}
                  />
                           </Flex>
              }

              {stage !== 2 && (
                <Flex justifyContent='center'>
                  <Text
                    fontWeight='700'
                    marginTop={['20px', '0', '0', '0']}
                    width={
                    stage === 0
                      ? ['100%', '100%', '100%', '100%']
                      : ['100%', '100%', '574px', '574px']
                  }
                    textAlign='center'
                    color={'#' + (headerColor || '#000000')}
                    fontSize={['1.75rem', '1.75rem', '2.25rem', '2.5rem']}
                    lineHeight='2.8125rem'
                  >
                    {headings[stage]}
                  </Text>
                </Flex>
              )}
            </Flex>

            {stages[stage]}
          </Box>
          {stage !== 2 && (
            <Flex marginTop='20px' justifyContent='center'>
              <Button
                isDisabled={stage === 0 && !booking}
                onClick={processNext}
                padding='10px 20px'
                minWidth='303px'
                fontSize='1.125rem'
                background={
                  `#${buttonColor || '4065FF'}`
                }
                fontWeight='600'
                lineHeight='1.75rem'
                height='48px'
                color={
                  `#${backPanelColor || 'ffffff'}`
                }
                borderRadius='6px'
                _hover={{ background: '#0031ff' }}
              >
                {actions[stage]}
              </Button>
            </Flex>
          )}
        </Flex>

        <style jsx>
          {`
            body.uppy-Dashboard-isFixed, html body.uppy-Dashboard-isFixed {
              overflow: initial !important;
              height: initial !important
            }

            .emoji-mart {
              z-index: 1000 !important;
            }

            .uppy-Webcam-video {
              max-height: 400px 
            }
            .calendar-items a {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 11px;
              color: #7e96a6;
              font-weight: bold;
              border-bottom :1px solid rgba(79, 111, 146, 0.15);
              font-size: 1.125rem;
            }

            #chatra__iframe, #chatra__iframe-wrapper {
              visibility: hidden !important; 
            }

            .tab-list-embed button[aria-selected="true"] {
              color: #4F6FF2 !important;
            }

            .type-description {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4 !important; /* number of lines to show */
                        line-clamp: 4 !important; 
                -webkit-box-orient: vertical;
            }

            .Toaster__manager-bottom {
              z-index: 100000 !important;
            }
          `}
        </style>
      </Box>
    </>
  )
}

// export function getStaticPaths () {
//   return {
//     paths: [],
//     fallback: 'blocking'
//   }
// }

export async function getServerSideProps (ctx) {
  const { slug } = ctx.params

  const {
    iframe,
    bgColor,
    headerColor,
    textColor,
    backPanelColor,
    dayColor,
    borderColor,
    oldDaysColor,
    weekDaysColor,
    buttonColor
  } = ctx.query

  const date = new Date()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  try {
    const { eventType, slots, author } =
      await publicEventTypeService.findByCodeAndPrepareSlots(
        {
          slug
        },
        Number(year),
        Number(month)
      )

    EventType.count()

    if (!eventType) {
      return {
        props: {},
        notFound: true
      }
    }

    return {
      props: {
        eventType: {
          slug: eventType.slug,
          title: eventType.title,
          emoji: eventType.emoji,
          background: eventType.background,
          id: eventType.id,
          author: eventType.author?.toString() || null,
          duration: eventType.duration,
          description: eventType.description,
          type: eventType.type
        },
        iframe: iframe === 'true',
        bgColor: bgColor ?? null,
        textColor: textColor ?? null,
        headerColor: headerColor ?? null,
        backPanelColor: backPanelColor ?? null,
        dayColor: dayColor ?? null,
        borderColor: borderColor ?? null,
        oldDaysColor: oldDaysColor ?? null,
        weekDaysColor: weekDaysColor ?? null,
        buttonColor: buttonColor ?? null,
        author,
        slots,
        year,
        month
      }
    }
  } catch (error) {
    logger.fatal(error)
    return {
      props: {},
      notFound: true
    }
  }
}

export default BookPage
