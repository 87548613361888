const { Box, Image, Text, Heading } = require('@chakra-ui/core')

const BookInfo = ({
  background,
  title,
  description,
  author,
  emoji,
  attendees,
  textColor,
  duration,
  backPanelColor,
  borderColor
}) => {
  return (
    <Box
      padding='20px'
      border={`1px solid #${borderColor ?? 'DBDBDB'}`}
      borderRadius='16px'
      background={'#' + (backPanelColor || 'fff')}
      width={['100%', '250px', '250px', '250px']}
      height={['100%', '420px', '100%', '100%']}
    >
      {background && (
        <Image
          src={background}
          alt={title}
          marginBottom='14px'
          borderRadius='12px'
        />
      )}
      <Text fontSize='0.875rem' opacity='0.65' color={'#' + textColor || '101010'}>
        By {author}
      </Text>
      <Heading
        as='h1'
        color={'#' + (textColor || '101010')}
        marginTop='0'
        marginBottom='8px'
        fontSize='1.25rem'
        fontWeight='700'
      >
        {title}
      </Heading>
      <Text color={'#' + (textColor || '101010')} fontSize='0.875rem' className='type-description-inner'>
        {description}
      </Text>
      <Box
        height='1px'
        backgroundColor={`#${borderColor ?? 'DBDBDB'}`}
        width='100%'
        marginBottom='18px'
        marginTop='18px'
      />
      <Box fontSize='0.875rem' color={'#' + (textColor || '1A202C')}>
        <Box display='flex' justifyContent='space-between'>
          <Text color={'#' + (textColor || '101010')} opacity='0.65'>
            Attendees:
          </Text>
          <Text>2</Text>
        </Box>
        <Box display='flex' justifyContent='space-between' marginTop='8px'>
          <Text color={'#' + (textColor || '101010')} opacity='0.65'>
            Duration:
          </Text>
          <Text>{duration / 1000 / 60} mins</Text>
        </Box>
      </Box>
    </Box>
  )
}

export { BookInfo }
