import { useLayoutEffect } from 'react'
import ReactDatePicker from 'react-datepicker'

const { Box } = require('@chakra-ui/core')

const BookCalendar = ({
  date,
  onDateClick,
  onMonthChange,
  duration,
  includeTimes,
  includeDates,
  backPanelColor,
  dayColor,
  textColor,
  borderColor,
  oldDaysColor,
  weekDaysColor,
  buttonColor
}) => {
  useLayoutEffect(() => {
    const updateSize = () => {
      const calendarWidth = document.querySelector('.react-datepicker__month-container').offsetWidth

      document.querySelector('.react-datepicker__navigation--previous').style.setProperty('left', `${calendarWidth * 0.1178}px`)
      document.querySelector('.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)').style.setProperty('left', `${calendarWidth * 0.91}px`)
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <Box height={['100%', '420px', '445px', '445px']} width={['100%', 'initial', 'initial', 'initial']} position='relative'>
      <ReactDatePicker
        fixedHeight
        selected={date}
        forceShowMonthNavigation
        showTimeSelect
        timeIntervals={duration / 1000 / 60}
        timeFormat='HH:mm'
        includeDates={includeDates}
        includeTimes={includeTimes}
        onChange={onDateClick}
        onSelect={onDateClick}
        onMonthChange={onMonthChange}
        startDate={new Date()}
        endDate={null}
        inline
      />
      <style jsx global>
        {`
          ${includeTimes.length === 0 ? `
          .react-datepicker__time-container {
            display: none;
          }
        ` : ''}

          .react-datepicker__time-list-item--disabled {
            display: none !important;
            position: relative;
          }

          .react-datepicker {
            font-size: 1.25rem;
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
            border: none !important;
          }

          @media screen and (max-width: 64em) {
            .react-datepicker {
              font-size: 1rem;
              width: 100%;
            }
          }

          @media screen and (max-width: 21em) {
            .react-datepicker {
              font-size: 0.85rem;
            }
          }

          .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            margin: 4px;
          }
          @media screen and (max-width: 64em) {
            .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
              margin: 1.5px;
            }
          }

          .react-datepicker, .react-datepicker__header, .react-datepicker__time-container {
            border: none;
          }

          .react-datepicker__time-container {
            width: 150px;
          }

          @media screen and (max-width: 64em) {
            .react-datepicker__time-container {
              width: 100%;
            }
          }

          .react-datepicker__header {
            background: #${backPanelColor || 'fff'};
            padding: 0;
          }

          .react-datepicker__day-names {
            margin-bottom: 0;
          }

          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
            width: 100%
          }

          .react-datepicker__day {
            background-color: #${dayColor || 'F5F5F5'};
            border-radius: 8px;
          }

          .react-datepicker__time-list-item {
            background-color: #${dayColor || 'F5F5F5'};
            margin-bottom: 10px !important;
          }

          .react-datepicker__day--disabled {
            border-color: transparent !important;
            background-color: transparent !important;
            color: #${textColor || '131a29'};
            opacity: 0.5;
          }

          // .react-datepicker__navigation {
          //   display: none !important;
          // }

          .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
            height: 24px;
            top: 31px;
          }


          @media screen and (max-width: 40em) {
            .react-datepicker__navigation--previous {
              left: 3.5%!important;
              top: 50px;
            }

            .react-datepicker .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
              right: 3.5%!important;
              left: initial!important;
              top: 50px;
            }
          }



          .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 0 !important;
          }

         .react-datepicker {
          backround: initial;
          background-color: initial;
         }

         @media screen and (max-width: 64em) {
          .react-datepicker {
            display: flex;
           }
         }

         @media screen and (max-width: 40em) {
          .react-datepicker {
            display: block;
           }
         }

         .react-datepicker__time-container .react-datepicker__time {
          background: #${backPanelColor || 'fff'};
         }

         .react-datepicker__month-container, .react-datepicker__time-container {
          padding: 20px;
          background: #${backPanelColor || 'fff'};
          border-radius: 16px;
          border: 1px solid #${borderColor || 'DBDBDB'};
          overflow: hidden;
          height: 445px;
         }

         @media screen and (max-width: 60em) {
          .react-datepicker__month-container, .react-datepicker__time-container {
            height: 420px;
          }
         }

         @media screen and (max-width: 40em) {
          .react-datepicker__month-container, .react-datepicker__time-container {
            height: 390px;
          }
         }

         @media screen and (max-width: 23em) {
          .react-datepicker__month-container, .react-datepicker__time-container {
            padding: 10px;
            height: auto !important;
          }
         }

         .react-datepicker__time-container {
          padding-bottom: 0px !important;
         }

         .react-datepicker__day-name {
          font-size: 1.25rem;
          color: #${weekDaysColor || 'C4C4C4'};
          font-weight: 700;
         }

         .react-datepicker__day--selected:hover {
          background: #${buttonColor || '4065FF'} !important;
          color: #${backPanelColor || 'fff'} !important;
          border-radius: 8px !important;
         }

         .react-datepicker__day--selected {
          background: #${buttonColor || '4065FF'} !important;
          color: #${backPanelColor || 'fff'} !important;
        }

         .react-datepicker__day--disabled:hover {
          background: transparent !important;
          color: #${textColor || '131a29'} !important;
         }

         .react-datepicker__time-list-item:hover {
          background: #${buttonColor || 'f0f0f0'} !important;
          color: #${backPanelColor || 'fff'} !important;
         }

         .react-datepicker__navigation-icon::before {
          border-color: #${weekDaysColor || 'cccccc'} !important;
         }

         @media screen and (max-width: 64em) {
          .react-datepicker__day-name {
            font-size: 1rem;
          }
         }

         .react-datepicker__day {
          width: 48px;
          height: 48px;
          line-height: 2.8rem;
          border: none;
          color: #${textColor || '131a29'};
          background: #${dayColor || 'F5F5F5'};
         }

         @media screen and (max-width: 64em) {
          .react-datepicker__day {
            width: 41px;
            height: 41px;
            line-height: 2.6rem;
          }
         }

         @media screen and (max-width: 23em) {
          .react-datepicker__day {
            width: calc(100vw * 0.109333);
            height: calc(100vw * 0.109333);
            line-height: calc(100vw * 0.109333);
          }
         }

         @media screen and (max-width: 21em) {
          .react-datepicker__day {
            width: calc(100vw * 0.1);
            height: calc(100vw * 0.1);
            line-height: calc(100vw * 0.1);
          }
         }

         .react-datepicker__day--outside-month {
          color: #${oldDaysColor || 'C4C4C4'};
         }

         .react-datepicker__current-month, .react-datepicker-time__header {
          font-size: 1.25rem;
          line-height: 2rem;
          font-weight: 700;
          color: #${textColor || '131a29'};
          margin-bottom: 8px;
         }

         @media screen and (max-width: 23em) {
          .react-datepicker__current-month, .react-datepicker-time__header {
            font-size: 1rem;
          }

          .react-datepicker__current-month {
            position: relative;
            top: 6px;
          }
         }

         .react-datepicker__day-name {
            width: 48px;
         }

         @media screen and (max-width: 64em) {
          .react-datepicker__day-name {
            width: 41px;
          }
         }

         @media screen and (max-width: 23em) {
          .react-datepicker__day-name {
            width: calc(100vw*.109333);
          }
         }

         @media screen and (max-width: 21em) {
          .react-datepicker__day-name {
            width: calc(100vw*.1);
            font-size: 0.85rem;
          }
         }

         .react-datepicker__month {
          margin: 0;
         }

         .react-datepicker__time-container {
          width: 224px;
         }

         @media screen and (max-width: 64em) {
          .react-datepicker__time-container {
            width: 160px;  
          }
         }

         @media screen and (max-width: 40em) {
          .react-datepicker__time-container {
            width: 100%;  
          }
         }

         .react-datepicker__month-container {
          margin: 0 20px;
         }

         @media screen and (max-width: 64em) {
          .react-datepicker__month-container {
            margin: 0 20px;
            width: auto;
            display: block;
          }
         }

         @media screen and (max-width: 40em) {
          .react-datepicker__month-container {
            margin: 20px 0 !important;
            width: 100%;
            display: block;
          }
         }

         .react-datepicker__time-list-item {
          margin: 0;
          font-size: 1.25rem;
          line-height: 2rem;
          border-radius: 8px;
          border: none;
          color: #${textColor || '131a29'};
         }

         @media screen and (max-width: 64em) {
          .react-datepicker__time-list-item {
            font-size: 1rem;
           }
         }

         .react-datepicker__time-list-item--selected, .react-datepicker__time-list-item--selected:hover, .react-datepicker__time-list-item--selected:active, .react-datepicker__time-list-item--selected:focus {
          background: #${buttonColor || '4065FF'} !important;
          color: #${backPanelColor || 'fff'} !important;
         }

         .previous-month::before {
          border-color: #${weekDaysColor || 'cccccc'} !important;
          transform: rotate(225deg);
          right: -7px;
          border-style: solid;
          border-width: 3px 3px 0 0;
          content: "";
          display: block;
          height: 9px;
          position: absolute;
          top: 6px;
          width: 9px;
          cursor: pointer;
         }

         .next-month::before {
          border-color: #${weekDaysColor || 'cccccc'} !important;
          transform: rotate(45deg);
          right: -7px;
          border-style: solid;
          border-width: 3px 3px 0 0;
          content: "";
          display: block;
          height: 9px;
          position: absolute;
          top: 6px;
          width: 9px;
          cursor: pointer;
         }

         .react-datepicker__time {
          height: calc(100% - 39px);
         }

         .react-datepicker__time-box {
          height: 100%;
         }

         .react-datepicker__time-list {
          height: 100%;
         }


         @media screen and (min-width: 64.01em) {
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
            height: 100% !important;
           }
         }
         `}
      </style>
    </Box>

  )
}

export { BookCalendar }
