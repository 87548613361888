import { FaFacebookMessenger, FaAt } from 'react-icons/fa'
import { BookMethodButton } from './book-method-button'

const { Box } = require('@chakra-ui/core')
const { TgIcon } = require('../icons/tg-icon')

const BookMethods = ({
  method, setMethod,
  backPanelColor, textColor,
  borderColor, buttonColor, dayColor
}) => {
  const eventOptions = {
    email: {
      title: 'Email',
      icon: <Box as={FaAt} size='21px' color='#000' />,
      iconWhite: <Box as={FaAt} size='21px' color='#fff' />,
      description: 'You will receive email with the reminder'
    },
    telegram: {
      title: 'Telegram',
      icon: <TgIcon />,
      iconWhite: <TgIcon white />,
      description: 'We will notify you with our telegram bot'
    },
    messenger: {
      title: 'Messenger',
      icon: <Box as={FaFacebookMessenger} size='21px' color='#000' />,
      iconWhite: <Box as={FaFacebookMessenger} size='21px' color='#fff' />,
      description: 'We will notify you with our messenger bot (temporarly disabled due technical reasons)',
      disabled: true,
    },
  }

  return (
    <Box
      padding='20px'
      border={`1px solid #${borderColor ?? 'DBDBDB'}`}
      borderRadius='16px'
      background={'#' + (backPanelColor || 'fff')}
      marginTop={['20px', '0', '0', '0']}
      marginLeft={['0', '20px', '20px', '20px']}
      width={['100%', '434px', '550px', '550px']}
      height={['100%', '420px', '100%', '100%']}
    >
      {Object.values(eventOptions).map(({ icon, title, iconWhite, description, disabled }) => (
        <BookMethodButton
          onClick={() => { setMethod(title.toLocaleLowerCase()) }}
          isActive={method === title.toLocaleLowerCase()}
          key={'method' + title}
          icon={icon}
          iconWhite={iconWhite}
          title={title}
          disabled={disabled}
          description={description}
          backPanelColor={backPanelColor} textColor={textColor}
          borderColor={borderColor} buttonColor={buttonColor} dayColor={dayColor}
        />
      ))}
    </Box>
  )
}

export { BookMethods }
