import {
  Box,
  Flex,
  Image,
  Avatar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
  Button
} from '@chakra-ui/core'
import NextLink from 'next/link'
import { FaCode } from 'react-icons/fa'

function NavbarButton ({ children, ...props }) {
  return (
    <Button
      _hover={{
        background: 'rgba(225, 231, 240, 0.18)'
      }}
      mr='10px'
      background='transparent'
      border='1px solid white'
      color='white'
      {...props}
    >
      {children}
    </Button>
  )
}

const BaseHeader = ({ user, onShare, onOpenEmbed, noEmbed }) => {
  return (
    <Flex justifyContent='space-between' alignItems='center' flex='0'>
      <NextLink href={user ? '/dashboard' : '/'}>
        <a>
          <Image
            src='/static/logo.svg'
            alt='hapen'
            hover='cursor'
            width={['100px', 'initial', 'initial', 'initial']}
          />
        </a>
      </NextLink>
      <Flex>
        {!user && (
          <>
            <NextLink href='/sign-in' passHref>
              <NavbarButton
                as='a'
                fontSize={['0.85rem', '1rem', '1rem', '1rem']}
                width={['9.6vw', '160px', '160px', '160px']}
                height={['9.6vw', '40px', '40px', '40px']}
                paddingLeft='0.5rem'
                paddingRight='0.5rem'
                color='#000'
                mr={['3.6vw', '15px', '15px', '15px']}
                borderColor='transparent'
                background='rgba(255, 255, 255, 0.25)'
              >
                <Image
                  src='/static/create-event.svg'
                  mr={['0px', '8px', '8px', '8px']}
                  width={['18px', 'initial', 'initial', 'initial']}
                  position='relative'
                  top={['0px', '-1px', '-1px', '-1px']}
                />
                <Box
                  as='span'
                  display={['none', 'inline', 'inline', 'inline']}
                >
                  Create your page
                </Box>
              </NavbarButton>
            </NextLink>
          </>
        )}
        <Box position='relative'>
          <Popover trigger='hover'>
            <PopoverTrigger>
              <NavbarButton
                as='span'
                width={['9.6vw', '40px', '40px', '40px']}
                height={['9.6vw', '40px', '40px', '40px']}
                background='#d7d7d7'
                borderColor='transparent'
                mr={['3.6vw', '15px', '15px', '15px']}
                minWidth='20px'
                cursor='pointer'
                onClick={onShare}
              >
                <Image
                  maxWidth='18px'
                  width='18px'
                  src='/static/share.svg'
                  position='relative'
                  top={['0px', '-1px', '-1px', '-1px']}
                />
              </NavbarButton>
            </PopoverTrigger>

            <PopoverContent
              position='absolute'
              maxWidth='80px'
              width='80px'
              top='48px'
              left='-20px'
              zIndex={4}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <PopoverArrow position='relative' top='-2px' />
              <PopoverBody>Share</PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
        {
          !noEmbed && (
            <Box position='relative'>
              <Popover trigger='hover'>
                <PopoverTrigger>
                  <NavbarButton
                    as='span'
                    width={['9.6vw', '40px', '40px', '40px']}
                    height={['9.6vw', '40px', '40px', '40px']}
                    background='#d7d7d7'
                    borderColor='transparent'
                    mr={['3.6vw', '15px', '15px', '15px']}
                    minWidth='20px'
                    paddingLeft='0'
                    paddingRight='0'
                    cursor='pointer'
                    onClick={onOpenEmbed}
                  >
                    <Box as={FaCode} width='18px' color='black' />
                  </NavbarButton>
                </PopoverTrigger>

                <PopoverContent
                  position='absolute'
                  maxWidth='80px'
                  width='80px'
                  top='48px'
                  left='-20px'
                  zIndex={4}
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                >
                  <PopoverArrow position='relative' top='-2px' />
                  <PopoverBody>Embed</PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          )
        }

        {user && (
          <NextLink passHref href='/dashboard'>
            <Box
              borderRadius='100%'
            // overflow='hidden'
              ml={['3.6vw', '15px', '15px', '15px']}
              as='a'
            >
              <Avatar
                width={['8.6vw', '36px', '36px', '36px']}
                height={['8.6vw', '36px', '36px', '36px']}
                src={user.avatarUrl}
                alt='profile'
              />
            </Box>
          </NextLink>
        )}
      </Flex>
    </Flex>
  )
}

export { BaseHeader }
