import { Button } from '@chakra-ui/core'

const BookStageButton = ({ isActive, index, onClick }) => {
  return (
    <Button
      onClick={onClick}
      fontWeight='600'
      background={isActive ? '#222227' : 'transparent'}
      padding='8px 16px'
      margin='0 8px'
      lineHeight='1.5rem'
      borderRadius='9999px'
      opacity={isActive ? '1' : '0.4'}
      color={isActive ? '#fff' : '#000'}
      fontSize={['0.75rem', '1rem', '1rem', '1rem']}
    >
      Step {index}
    </Button>
  )
}

export { BookStageButton }
