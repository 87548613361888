const { Box, Flex, Button, Text } = require('@chakra-ui/core')

const BookMethodButton = ({
  isActive,
  iconWhite,
  icon,
  title,
  description,
  onClick,
  disabled,
  backPanelColor, textColor,
  borderColor, buttonColor, dayColor
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      width='100%'
      display='flex'
      padding='14px 12px'
      justifyContent='flex-start'
      alignItems='center'
      height='73px'
      borderRadius='14px'
      border={isActive ? `1px solid #${borderColor || 'e7e7e7'}` : '1px solid transparent'}
      background={isActive ? `#${dayColor || 'F5F5F5'}` : 'transparent'}
      marginBottom='14px'
      outline='false'
      _hover={{
        background: `#${dayColor || 'f3f3f3'}`
      }}
    >
      <Flex
        width={['44px', '44px', '44px', '44px']}
        height={['44px', '44px', '44px', '44px']}
        alignItems='center'
        justifyContent='center'
        background={isActive ? `#${buttonColor || '4065FF'}` : `#${textColor || 'F5F5F5'}`}
        marginRight={['16px', '16px', '16px', '16px']}
        borderRadius='7px'
      >
        {isActive ? iconWhite : icon}
      </Flex>
      <Box display='block' width='calc(100% - 60px);'>
        <Text
          fontSize='1.125rem'
          fontWeight='700'
          lineHeight='1.375rem'
          color={'#' + (textColor || '101010')}
          textAlign='left'
          display='block'
          width='100%'
        >
          {title}
        </Text>
        <Text
          display={['none', 'block', 'block', 'block']}
          textAlign='left'
          lineHeight='1.47rem'
          color={'#' + (textColor || '101010')}
          opacity='0.6'
          fontWeight='400'
          width='100%'
          fontSize={['0.725rem', '1rem', '1rem', '1rem']}
          whiteSpace='normal'
        >
          {description}
        </Text>
      </Box>
    </Button>
  )
}

export { BookMethodButton }
