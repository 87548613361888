const TgIcon = ({ white }) => {
  return (
    <svg width='21' height='19' viewBox='0 0 21 19' fill='none'>
      <path
        d='M8.24023 12.3797L7.89285 17.4341C8.38986 17.4341 8.60511 17.2133 8.86324 16.948L11.1934 14.6444L16.0218 18.3022C16.9073 18.8127 17.5312 18.5438 17.7701 17.4595L20.9394 2.09695L20.9403 2.09605C21.2212 0.741914 20.4669 0.212391 19.6041 0.544588L0.974929 7.92261C-0.296476 8.43312 -0.277226 9.16631 0.758799 9.49851L5.52154 11.031L16.5844 3.87017C17.1051 3.51354 17.5785 3.71086 17.1891 4.0675L8.24023 12.3797Z'
        fill='#000000'
        style={
          white
            ? {
                fill: '#ffffff'
              }
            : {}
        }
      />
    </svg>
  )
}

export { TgIcon }
